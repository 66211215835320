import EventEmitter from './EventEmitter';
export default class Mouse extends EventEmitter {
    constructor() {
        super();
        // Setup
        this.x = 0;
        this.y = 0;
        this.normalizedX = 0;
        this.normalizedY = 0;
        this.inComputer = false;
        // Listen for mousemove events
        this.on('mousemove', (event) => {
            if (event.clientX && event.clientY) {
                this.x = event.clientX;
                this.y = event.clientY;
                // Normalize mouse coordinates to [-1, 1]
                this.normalizedX = (this.x / window.innerWidth) * 2 - 1;
                this.normalizedY = -(this.y / window.innerHeight) * 2 + 1;
            }
            this.inComputer = event.inComputer ? true : false;
        });
    }
}
