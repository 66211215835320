import * as THREE from 'three';
import Application from '../Application';
export default class WindowView {
    constructor() {
        this.application = new Application();
        this.scene = this.application.scene;
        this.resources = this.application.resources;
        this.addWindowView();
        this.addBlackPlane1();
        this.addBlackPlane2(); // Add the second black plane
    }
    addWindowView() {
        const texture = this.resources.items.texture.windowView;
        if (!texture) {
            console.error("WindowView texture not found!");
            return;
        }
        texture.encoding = THREE.sRGBEncoding;
        texture.needsUpdate = true;
        const geometry = new THREE.PlaneGeometry(10000 * 2, 8000 * 2);
        const material = new THREE.MeshBasicMaterial({
            map: texture,
            opacity: 0.4,
            transparent: true,
        });
        this.windowMesh = new THREE.Mesh(geometry, material);
        // Position and rotation same as before
        this.windowMesh.position.set(-2000, 1600, -8000);
        this.windowMesh.rotation.y = Math.PI / 4.5;
        this.scene.add(this.windowMesh);
    }
    addBlackPlane1() {
        const geometry = new THREE.PlaneGeometry(10000 * 0.5, 8000 * 0.7); // Slightly larger than window view
        const material = new THREE.MeshBasicMaterial({
            color: 0x000000, // Black color
        });
        this.blackPlaneMesh1 = new THREE.Mesh(geometry, material);
        // Position the first black plane slightly behind the window mesh
        this.blackPlaneMesh1.position.set(1800, -1000, -1500);
        this.blackPlaneMesh1.rotation.y = THREE.MathUtils.degToRad(-50);
        this.scene.add(this.blackPlaneMesh1);
    }
    addBlackPlane2() {
        const geometry = new THREE.PlaneGeometry(10000 * 0.34, 8000 * 0.5); // Adjust size as needed
        const material = new THREE.MeshBasicMaterial({
            color: 0x000000, // Black color
        });
        this.blackPlaneMesh2 = new THREE.Mesh(geometry, material);
        // Position the second black plane differently
        this.blackPlaneMesh2.position.set(-1100, -1000, -2100);
        this.blackPlaneMesh2.rotation.y = THREE.MathUtils.degToRad(40);
        this.scene.add(this.blackPlaneMesh2);
    }
    update() {
        // Any updates you need can be handled here
    }
}
