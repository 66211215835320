import * as THREE from 'three';
import Application from '../Application';
import EventEmitter from '../Utils/EventEmitter';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import TWEEN from '@tweenjs/tween.js';
import UIEventBus from '../UI/EventBus';
import BezierEasing from 'bezier-easing';
import { MonitorKeyframe, IdleKeyframe, LoadingKeyframe, DeskKeyframe, OrbitControlsStart, PendantKeyframe, CDKeyframe, } from './CameraKeyframes';
// Enum to represent different camera states
export var CameraKey;
(function (CameraKey) {
    CameraKey["IDLE"] = "idle";
    CameraKey["MONITOR"] = "monitor";
    CameraKey["LOADING"] = "loading";
    CameraKey["DESK"] = "desk";
    CameraKey["ORBIT_CONTROLS_START"] = "orbitControlsStart";
    CameraKey["PENDANT"] = "pendant";
    CameraKey["CD"] = "cd";
})(CameraKey || (CameraKey = {}));
export default class Camera extends EventEmitter {
    constructor() {
        super();
        this.application = new Application();
        this.sizes = this.application.sizes;
        this.scene = this.application.scene;
        this.renderer = this.application.renderer;
        this.resources = this.application.resources;
        this.time = this.application.time;
        this.position = new THREE.Vector3(0, 0, 0);
        this.focalPoint = new THREE.Vector3(0, 0, 0);
        this.freeCam = false;
        // Initialize camera keyframes
        this.keyframes = {
            idle: new IdleKeyframe(),
            monitor: new MonitorKeyframe(),
            loading: new LoadingKeyframe(),
            desk: new DeskKeyframe(),
            orbitControlsStart: new OrbitControlsStart(),
            pendant: new PendantKeyframe(),
            cd: new CDKeyframe(),
        };
        // Event listener for mouse clicks to manage keyframe transitions
        document.addEventListener('mousedown', (event) => {
            event.preventDefault();
            if (event.target instanceof HTMLElement && event.target.id === 'prevent-click')
                return;
            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera({ x: this.application.mouse.normalizedX, y: this.application.mouse.normalizedY }, this.instance);
            const intersects = raycaster.intersectObjects(this.scene.children);
            const clickedObject = intersects.length > 0 ? intersects[0].object : null;
            if (this.currentKeyframe === CameraKey.IDLE || this.targetKeyframe === CameraKey.IDLE) {
                if (clickedObject && this.application.hitboxManager.hitboxes[clickedObject.name]) {
                    if (clickedObject.name === 'pendantHitbox') {
                        this.transition(CameraKey.PENDANT);
                    }
                    else if (clickedObject.name === 'cdHitbox') {
                        this.transition(CameraKey.CD);
                    }
                    else {
                        this.transition(CameraKey.DESK);
                    }
                }
                else {
                    this.transition(CameraKey.DESK);
                }
                return;
            }
            if (this.currentKeyframe === CameraKey.DESK ||
                this.currentKeyframe === CameraKey.PENDANT ||
                this.currentKeyframe === CameraKey.CD) {
                this.transition(CameraKey.IDLE);
                return;
            }
        });
        this.setPostLoadTransition();
        this.setInstance();
        this.setMonitorListeners();
        this.setFreeCamListeners();
    }
    // Handles transitions between camera keyframes
    transition(key, duration = 1000, easing, callback) {
        if (this.currentKeyframe === key)
            return;
        if (this.targetKeyframe)
            TWEEN.removeAll();
        this.currentKeyframe = undefined;
        this.targetKeyframe = key;
        const keyframe = this.keyframes[key];
        const posTween = new TWEEN.Tween(this.position)
            .to(keyframe.position, duration)
            .easing(easing || TWEEN.Easing.Quintic.InOut)
            .onComplete(() => {
            this.currentKeyframe = key;
            this.targetKeyframe = undefined;
            if (callback)
                callback();
        });
        const focTween = new TWEEN.Tween(this.focalPoint)
            .to(keyframe.focalPoint, duration)
            .easing(easing || TWEEN.Easing.Quintic.InOut);
        posTween.start();
        focTween.start();
    }
    // Sets the initial camera instance
    setInstance() {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 10, 900000);
        this.currentKeyframe = CameraKey.LOADING;
        this.scene.add(this.instance);
    }
    // Handles transitions when entering or leaving the monitor keyframe
    setMonitorListeners() {
        this.on('enterMonitor', () => {
            this.transition(CameraKey.MONITOR, 2000, BezierEasing(0.13, 0.99, 0, 1));
            UIEventBus.dispatch('enterMonitor', {});
        });
        this.on('leftMonitor', () => {
            this.transition(CameraKey.DESK);
            UIEventBus.dispatch('leftMonitor', {});
        });
    }
    // Listens for toggling free camera mode
    setFreeCamListeners() {
        UIEventBus.on('freeCamToggle', (toggle) => {
            if (toggle) {
                this.transition(CameraKey.ORBIT_CONTROLS_START, 750, BezierEasing(0.13, 0.99, 0, 1), () => {
                    this.instance.position.copy(this.keyframes.orbitControlsStart.position);
                    this.orbitControls.update();
                    this.freeCam = true;
                });
                const webglElement = document.getElementById('webgl');
                if (webglElement) {
                    webglElement.style.pointerEvents = 'auto';
                }
            }
            else {
                this.freeCam = false;
                this.transition(CameraKey.IDLE, 4000, TWEEN.Easing.Exponential.Out);
                const webglElement = document.getElementById('webgl');
                if (webglElement) {
                    webglElement.style.pointerEvents = 'none';
                }
            }
        });
    }
    // Transitions to the idle keyframe after loading is complete
    setPostLoadTransition() {
        UIEventBus.on('loadingScreenDone', () => {
            this.transition(CameraKey.IDLE, 5000, TWEEN.Easing.Exponential.Out);
        });
    }
    // Adjusts the camera aspect ratio on resize
    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height;
        this.instance.updateProjectionMatrix();
    }
    // Creates orbit controls for free camera mode
    createControls() {
        this.renderer = this.application.renderer;
        this.orbitControls = new OrbitControls(this.instance, this.renderer.instance.domElement);
        const { x, y, z } = this.keyframes.orbitControlsStart.focalPoint;
        this.orbitControls.target.set(x, y, z);
        this.orbitControls.enablePan = false;
        this.orbitControls.enableDamping = true;
        this.orbitControls.object.position.copy(this.keyframes.orbitControlsStart.position);
        this.orbitControls.dampingFactor = 0.05;
        this.orbitControls.maxPolarAngle = Math.PI / 2;
        this.orbitControls.minDistance = 4000;
        this.orbitControls.maxDistance = 29000;
        this.orbitControls.update();
    }
    // Updates the camera position and focal point based on the current keyframe
    update() {
        TWEEN.update();
        if (this.freeCam && this.orbitControls) {
            this.position.copy(this.orbitControls.object.position);
            this.focalPoint.copy(this.orbitControls.target);
            this.orbitControls.update();
            return;
        }
        for (const key in this.keyframes) {
            this.keyframes[key].update();
        }
        if (this.currentKeyframe) {
            const keyframe = this.keyframes[this.currentKeyframe];
            this.position.copy(keyframe.position);
            this.focalPoint.copy(keyframe.focalPoint);
        }
        this.instance.position.copy(this.position);
        this.instance.lookAt(this.focalPoint);
    }
}
