import * as THREE from 'three';
import Application from '../Application';
export default class Hitbox {
    constructor() {
        this.application = new Application();
        this.scene = this.application.scene;
        this.camera = this.application.camera;
        this.mouse = this.application.mouse;
        this.raycaster = new THREE.Raycaster();
        this.hitboxes = {}; // Object to store hitboxes and their actions
        this.createRaycaster(); // Set up the raycaster for detecting clicks
        this.createPendantHitbox(); // Create the pendant hitbox
        this.createCdHitbox(); // Create the CD hitbox
    }
    // Method to set up the raycaster and detect interactions with hitboxes
    createRaycaster() {
        window.addEventListener('mousedown', (event) => {
            event.preventDefault();
            // Use the normalized mouse coordinates for raycasting
            this.raycaster.setFromCamera({ x: this.mouse.normalizedX, y: this.mouse.normalizedY }, this.camera.instance);
            const intersects = this.raycaster.intersectObjects(this.scene.children);
            if (intersects.length > 0) {
                const clickedObject = intersects[0].object;
                const hb = this.hitboxes[clickedObject.name];
                if (hb) {
                    hb.action(); // Trigger the associated action when hitbox is clicked
                }
            }
        });
    }
    // Method to create a hitbox for the pendant object
    createPendantHitbox() {
        this.createHitbox('pendantHitbox', () => {
            console.log('Pendant hitbox clicked');
        }, new THREE.Vector3(-3000, 0, 2400), new THREE.Vector3(1000, 1000, 1000));
    }
    // Method to create a hitbox for the CD object
    createCdHitbox() {
        this.createHitbox('cdHitbox', () => {
            console.log('CD hitbox clicked');
        }, new THREE.Vector3(3200, -300, 3400), new THREE.Vector3(1200, 1000, 1200));
    }
    // Generic method to create a hitbox with a specified position, size, and action
    createHitbox(name, action, position, size) {
        const hitboxMaterial = new THREE.MeshBasicMaterial({
            color: 0xff0000,
            wireframe: false,
            transparent: true,
            opacity: 0,
            depthWrite: false, // Disable depth write to avoid rendering issues
        });
        const hitbox = new THREE.Mesh(new THREE.BoxBufferGeometry(size.x, size.y, size.z), hitboxMaterial);
        hitbox.name = name;
        hitbox.position.copy(position);
        this.scene.add(hitbox); // Add the hitbox to the scene
        this.hitboxes[name] = { action }; // Store the hitbox action
    }
}
