import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import UIEventBus from '../EventBus';

const HELP_TEXT = 'Click anywhere to begin';

type HelpPromptProps = {};

const HelpPrompt: React.FC<HelpPromptProps> = () => {
    const [helpText, setHelpText] = useState('');
    const [visible, setVisible] = useState(true);
    const visRef = useRef(visible); // Use ref to keep track of visibility state across re-renders

    /**
     * Types out the help text one character at a time.
     * @param i - The current index in the help text.
     * @param curText - The current text being displayed.
     */
    const typeHelpText = (i: number, curText: string) => {
        if (i < HELP_TEXT.length && visRef.current) {
            setTimeout(() => {
                window.postMessage(
                    { type: 'keydown', key: `_AUTO_${HELP_TEXT[i]}` },
                    '*'
                );
                setHelpText(curText + HELP_TEXT[i]);
                typeHelpText(i + 1, curText + HELP_TEXT[i]);
            }, Math.random() * 120 + 50);
        }
    };

    useEffect(() => {
        // Start typing the help text after a delay
        setTimeout(() => {
            typeHelpText(0, '');
        }, 500);

        // Hide the help prompt on mouse down or entering monitor view
        const hidePrompt = () => setVisible(false);
        document.addEventListener('mousedown', hidePrompt);
        UIEventBus.on('enterMonitor', hidePrompt);

        return () => {
            document.removeEventListener('mousedown', hidePrompt);
            UIEventBus.remove('enterMonitor', hidePrompt); // Correctly remove event listener
        };
    }, []);

    useEffect(() => {
        if (!visible) {
            window.postMessage({ type: 'keydown', key: `_AUTO_` }, '*');
        }
        visRef.current = visible; // Update ref with the current visibility state
    }, [visible]);

    return helpText.length > 0 ? (
        <motion.div
            variants={vars}
            animate={visible ? 'visible' : 'hide'}
            style={styles.container as React.CSSProperties}
        >
            <p>{helpText}</p>
            <div style={styles.blinkingContainer as React.CSSProperties}>
                <div className="blinking-cursor" />
            </div>
        </motion.div>
    ) : null;
};

// Animation variants for showing and hiding the help prompt
const vars = {
    visible: {
        opacity: 1,
    },
    hide: {
        y: 12,
        opacity: 0,
        transition: {
            duration: 0.5,
            ease: 'easeOut',
        },
    },
};

// Inline styles for the component
const styles = {
    container: {
        position: 'absolute',
        bottom: 64,
        background: 'black',
        padding: 4,
        paddingLeft: 16,
        paddingRight: 16,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'flex-end',
    },
    blinkingContainer: {
        marginLeft: 8,
        paddingBottom: 2,
        paddingRight: 4,
    },
};

export default HelpPrompt;