import * as THREE from 'three';
import Time from '../Utils/Time';
import Application from '../Application';
export class CameraKeyframeInstance {
    constructor(keyframe) {
        this.position = keyframe.position;
        this.focalPoint = keyframe.focalPoint;
    }
    update() { }
}
const keys = {
    idle: {
        position: new THREE.Vector3(-10000, 9000, 15000),
        focalPoint: new THREE.Vector3(0, -1000, 0),
    },
    monitor: {
        position: new THREE.Vector3(0, 950, 2000),
        focalPoint: new THREE.Vector3(0, 950, 0),
    },
    desk: {
        position: new THREE.Vector3(0, 1800, 5500),
        focalPoint: new THREE.Vector3(0, 500, 0),
    },
    loading: {
        position: new THREE.Vector3(-14000, 9000, 21000),
        focalPoint: new THREE.Vector3(0, -5000, 0),
    },
    orbitControlsStart: {
        position: new THREE.Vector3(-15000, 10000, 15000),
        focalPoint: new THREE.Vector3(-100, 350, 0),
    },
    pendant: {
        position: new THREE.Vector3(-1500, 1000, 4400),
        focalPoint: new THREE.Vector3(-3000, 0, 2400),
    },
    cd: {
        position: new THREE.Vector3(100, 4000, 6000),
        focalPoint: new THREE.Vector3(3200, -300, 3400),
    },
};
// Monitor keyframe with adaptive zoom based on aspect ratio
export class MonitorKeyframe extends CameraKeyframeInstance {
    constructor() {
        const keyframe = keys.monitor;
        super(keyframe);
        this.application = new Application();
        this.sizes = this.application.sizes;
        this.origin = new THREE.Vector3().copy(keyframe.position);
        this.targetPos = new THREE.Vector3().copy(keyframe.position);
    }
    update() {
        const aspect = this.sizes.height / this.sizes.width;
        const additionalZoom = this.sizes.width < 768 ? 0 : 600;
        this.targetPos.z = this.origin.z + aspect * 1200 - additionalZoom;
        this.position.copy(this.targetPos);
    }
}
// Loading keyframe with no special updates
export class LoadingKeyframe extends CameraKeyframeInstance {
    constructor() {
        const keyframe = keys.loading;
        super(keyframe);
    }
    update() { }
}
// Desk keyframe with slight camera movement based on mouse position
export class DeskKeyframe extends CameraKeyframeInstance {
    constructor() {
        const keyframe = keys.desk;
        super(keyframe);
        this.application = new Application();
        this.mouse = this.application.mouse;
        this.sizes = this.application.sizes;
        this.origin = new THREE.Vector3().copy(keyframe.position);
        this.targetFoc = new THREE.Vector3().copy(keyframe.focalPoint);
        this.targetPos = new THREE.Vector3().copy(keyframe.position);
    }
    update() {
        this.targetFoc.x += (this.mouse.x - this.sizes.width / 2 - this.targetFoc.x) * 0.05;
        this.targetFoc.y += (-(this.mouse.y - this.sizes.height) - this.targetFoc.y) * 0.05;
        this.targetPos.x += (this.mouse.x - this.sizes.width / 2 - this.targetPos.x) * 0.025;
        this.targetPos.y += (-(this.mouse.y - this.sizes.height * 2) - this.targetPos.y) * 0.025;
        const aspect = this.sizes.height / this.sizes.width;
        this.targetPos.z = this.origin.z + aspect * 3000 - 1800;
        this.focalPoint.copy(this.targetFoc);
        this.position.copy(this.targetPos);
    }
}
// Idle keyframe with subtle camera oscillation over time
export class IdleKeyframe extends CameraKeyframeInstance {
    constructor() {
        const keyframe = keys.idle;
        super(keyframe);
        this.origin = new THREE.Vector3().copy(keyframe.position);
        this.time = new Time();
    }
    update() {
        this.position.x = Math.sin((this.time.elapsed + 19000) * 0.00008) * this.origin.x;
        this.position.y = Math.sin((this.time.elapsed + 1000) * 0.000004) * 4000 + this.origin.y - 3000;
        // Z-position remains constant (redundant assignment removed)
    }
}
// Starting position for orbit controls (no special updates)
export class OrbitControlsStart extends CameraKeyframeInstance {
    constructor() {
        const keyframe = keys.orbitControlsStart;
        super(keyframe);
    }
    update() { }
}
// Pendant keyframe with camera movement influenced by mouse position
export class PendantKeyframe extends CameraKeyframeInstance {
    constructor() {
        const keyframe = keys.pendant;
        super(keyframe);
        this.application = new Application();
        this.mouse = this.application.mouse;
        this.sizes = this.application.sizes;
        this.origin = new THREE.Vector3().copy(keyframe.position);
        this.targetFoc = new THREE.Vector3().copy(keyframe.focalPoint);
        this.targetPos = new THREE.Vector3().copy(keyframe.position);
    }
    update() {
        this.targetFoc.x += (-3000 + (this.mouse.x - this.sizes.width * 0.5) * 0.25 - this.targetFoc.x) * 0.05;
        this.targetFoc.y += (-100 + (-(this.mouse.y - this.sizes.height) * 0.25) - this.targetFoc.y) * 0.05;
        this.targetFoc.z += (2200 + (-(this.mouse.x - this.sizes.width) * 0.5) * 0.25 - this.targetFoc.z) * 0.05;
        this.targetPos.x += (-1500 + (this.mouse.x - this.sizes.width / 2) * 0.25 - this.targetPos.x) * 0.025;
        this.targetPos.y += (1000 + (-(this.mouse.y - this.sizes.height) * 0.025) - this.targetPos.y) * 0.05;
        this.targetPos.z += (4400 + (-(this.mouse.x - this.sizes.width) / 2) * 0.25 - this.targetPos.z) * 0.025;
        this.focalPoint.copy(this.targetFoc);
        this.position.copy(this.targetPos);
    }
}
// CD keyframe with camera movement influenced by mouse position
export class CDKeyframe extends CameraKeyframeInstance {
    constructor() {
        const keyframe = keys.cd;
        super(keyframe);
        this.application = new Application();
        this.mouse = this.application.mouse;
        this.sizes = this.application.sizes;
        this.origin = new THREE.Vector3().copy(keyframe.position);
        this.targetFoc = new THREE.Vector3().copy(keyframe.focalPoint);
        this.targetPos = new THREE.Vector3().copy(keyframe.position);
    }
    update() {
        this.targetFoc.x += (3200 + (this.mouse.x - this.sizes.width * 0.5) * 0.25 - this.targetFoc.x) * 0.05;
        this.targetFoc.y += (-300 + (-(this.mouse.y - this.sizes.height) * 0.5) - this.targetFoc.y) * 0.05;
        this.targetFoc.z += (3400 + (this.mouse.x - this.sizes.width * 0.5) * 0.25 - this.targetFoc.z) * 0.05;
        this.targetPos.x += (100 + (this.mouse.x - this.sizes.width / 2) * 0.25 - this.targetPos.x) * 0.025;
        this.targetPos.y += (4000 + (-(this.mouse.y - this.sizes.height) * 0.1) - this.targetPos.y) * 0.05;
        this.targetPos.z += (6000 + (this.mouse.x - this.sizes.width / 2) * 0.25 - this.targetPos.z) * 0.025;
        // Keep the focal point and position locked on the cd hitbox
        this.focalPoint.copy(this.targetFoc);
        this.position.copy(this.targetPos);
    }
}
