import * as THREE from 'three';
import Application from '../Application';
export default class Pendant {
    constructor() {
        this.application = new Application();
        this.scene = this.application.scene;
        this.resources = this.application.resources;
        this.clock = new THREE.Clock(); // Create a clock for animation timing
        this.setModel();
        this.addLighting(); // Add lighting within the Pendant class
    }
    setModel() {
        // Load the pendant model from resources
        this.pendant = this.resources.items.gltfModel.pendantModel.scene;
        // Apply a reflective metal material
        const reflectiveMaterial = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            metalness: 1,
            roughness: 0.3, // Low roughness for a shiny, reflective surface
        });
        this.pendant.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = reflectiveMaterial;
            }
        });
        // Set initial position, scale, and rotation
        this.pendant.position.set(-3000, 0, 2400);
        this.pendant.scale.set(800, 800, 800);
        this.pendant.rotation.set(0, 0.66, 0);
        // Add the pendant to the scene
        this.scene.add(this.pendant);
    }
    addLighting() {
        // Create a spotlight to highlight the pendant
        this.spotLight = new THREE.SpotLight(0xffe4b5, 10); // White light, intensity 10
        this.spotLight.position.set(-3200, 2000, 2800); // Position the spotlight above the pendant
        // Set spotlight properties
        this.spotLight.angle = 0.14; // Spread of the spotlight beam
        this.spotLight.penumbra = 0.4; // Softness of the edges
        this.spotLight.decay = 2; // How the light dims over distance
        this.spotLight.distance = 10000; // Maximum range of the spotlight
        // Point the spotlight at the pendant
        this.spotLight.target = this.pendant;
        // Add the spotlight to the scene
        this.scene.add(this.spotLight);
    }
    update() {
        if (this.pendant) {
            // Perform the wobbling animation
            const time = this.clock.getElapsedTime();
            this.pendant.rotation.x = Math.sin(time * 0.4) * 0.1;
            this.pendant.rotation.y = 0.66 + Math.sin(time) * 0.2;
            this.pendant.rotation.z = Math.sin(time * 0.5) * 0.1;
        }
    }
}
