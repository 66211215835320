import * as THREE from 'three';
import Application from '../Application';
import BakedModel from '../Utils/BakedModel';
export default class Environment {
    constructor() {
        this.application = new Application();
        this.scene = this.application.scene;
        this.resources = this.application.resources;
        this.bakeModel();
        this.setModel();
        this.setRenderOrder(); // Adjust render order
    }
    bakeModel() {
        this.bakedModel = new BakedModel(this.resources.items.gltfModel.environmentModel, this.resources.items.texture.environmentTexture, 900, true);
    }
    setModel() {
        this.scene.add(this.bakedModel.getModel());
    }
    setRenderOrder() {
        // Traverse the entire environment model and adjust the render order for all meshes
        this.bakedModel.getModel().traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.renderOrder = 1; // Set this value as needed
            }
        });
    }
    update() { }
}
